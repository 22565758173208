









import { Component, Vue } from 'vue-property-decorator';
import { Action, Getter } from "vuex-class";
import Region from '@/model/region'
import { DataTableHeader } from 'node_modules/vuetify/types';
import axios from 'axios';
import API from '@/store/api';

@Component({
  components: {

  },
})
export default class Home extends Vue {

  private regions: Region[] = [];
  private currentRegion: number = 0;

  private headers: DataTableHeader[] = [
    { text: 'ID', value: 'id' },
    { text: 'Name', value: 'name' },
    { text: 'Name (international)', value: 'name_int' },
  ];

  created(){
    if(!this.currentRegion)
      this.currentRegion = 0;

    axios.get(API.URL_REGIONS(0)).then(response => {
      this.regions = response.data;
    }).catch((error) => {
      window.console.error(error);
    });
  }
}
